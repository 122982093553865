<template>
  <div>
     <!-- slider Area Start-->
     <div class="slider-area ">
        <!-- Mobile Menu -->
        <div class="single-slider slider-height2 d-flex align-items-center" data-background="assets/img/hero/about.jpg">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="hero-cap text-center">
                            <h2>List Kegiatan</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
     <!-- slider Area End-->
    <!--================Blog Area =================-->
    <section class="blog_area section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 mb-5 mb-lg-0">
                    <div class="blog_left_sidebar">
                        <article class="blog_item" v-for="data in list_data" :key="data.id">
                            <div class="blog_item_img">
                                <!-- <img class="card-img rounded-0" src="assets/img/blog/single_blog_1.png" alt=""> -->
                                <img class="card-img rounded-0" :src="$store.state.url.URL_APP+'uploads/'+data.foto" alt="">


                                <!-- {{$store.state.url.URL_APP+'uploads/'+data.foto}} -->
                                <a href="#" class="blog_item_date">
                                    <h3>{{UMUM.tglConvert(data.createAt).tglx}}</h3>
                                    <p>{{UMUM.tglConvert(data.createAt).blnx}}</p>
                                </a>
                            </div>

                            <div class="blog_details">
                                <!-- <h1>{{UMUM.tglConvert(data.createAt).tgl}}</h1> -->
                                <a class="d-inline-block" href="javascript:void(0)" @click="routerToPlace('/kegiatanDetile?idx='+data.keyx)">
                                    <h2>{{data.nama}}</h2>
                                </a>
                                <p>{{data.rangkuman}}</p>
                                <ul class="blog-info-link">
                                    <li><a href="#"><i class="fa fa-user"></i> {{data.postBy}}</a></li>
                                    <li><a href="#"><i class="fa fa-comments"></i> {{UMUM.tglConvert(data.createAt).tgl}}</a></li>
                                </ul>
                            </div>
                        </article>

                        <nav class="blog-pagination justify-content-center d-flex">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a href="#" class="page-link" aria-label="Previous">
                                        <i class="ti-angle-left"></i>
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a href="#" class="page-link">1</a>
                                </li>
                                <li class="page-item active">
                                    <a href="#" class="page-link">2</a>
                                </li>
                                <li class="page-item">
                                    <a href="#" class="page-link" aria-label="Next">
                                        <i class="ti-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="blog_right_sidebar">
                        <aside class="single_sidebar_widget search_widget">
                            <form action="#">
                                <div class="form-group">
                                    <div class="input-group mb-3">
                                        <input v-model="cari_value" type="text" class="form-control" placeholder='Search Keyword' onfocus="this.placeholder = ''" onblur="this.placeholder = 'Search Keyword'">
                                        <div class="input-group-append">
                                            <button @click="cari_data()" class="btns" type="button"><i class="ti-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <button  @click="cari_data()" class="button rounded-0 primary-bg text-white w-100 btn_1 boxed-btn"
                                    type="submit">Search</button>
                            </form>
                        </aside>


                        <aside class="single_sidebar_widget popular_post_widget">
                            <h3 class="widget_title">Recent Post</h3>
                            <div class="media post_item">
                                <img src="assets/img/post/post_1.png" alt="post">
                                <div class="media-body">
                                    <a href="single-blog.html">
                                        <h3>From life was you fish...</h3>
                                    </a>
                                    <p>January 12, 2019</p>
                                </div>
                            </div>
                            <div class="media post_item">
                                <img src="assets/img/post/post_2.png" alt="post">
                                <div class="media-body">
                                    <a href="single-blog.html">
                                        <h3>The Amazing Hubble</h3>
                                    </a>
                                    <p>02 Hours ago</p>
                                </div>
                            </div>
                            <div class="media post_item">
                                <img src="assets/img/post/post_3.png" alt="post">
                                <div class="media-body">
                                    <a href="single-blog.html">
                                        <h3>Astronomy Or Astrology</h3>
                                    </a>
                                    <p>03 Hours ago</p>
                                </div>
                            </div>
                            <div class="media post_item">
                                <img src="assets/img/post/post_4.png" alt="post">
                                <div class="media-body">
                                    <a href="single-blog.html">
                                        <h3>Asteroids telescope</h3>
                                    </a>
                                    <p>01 Hours ago</p>
                                </div>
                            </div>
                        </aside>
               


                        <fotoLainnya/>



                    </div>
                </div>
            </div>
        </div>
    </section>
    <script type="application/javascript" defer src="./assets/js/plugins.js"></script>
    <script type="application/javascript" defer src="./assets/js/main.js"></script>
  </div>
</template>

<script>

import UMUM from "../../library/umum";

export default {
    data() {
        return {
            form : {
                id: "",
                nama: "",
                deskripsi: "",
                sumber : '',
                userId : '',
                tgl: "",

                file: null,
            },

            UMUM : UMUM,

            list_data : [],
            fileOld : '',

            page_first: 1,
            page_last: 0,


            page_limit : 6,
            cari_value: "",
        }
    },

    methods: {
        getView : function(){
            // this.$store.commit("shoWLoading");
            fetch(this.$store.state.url.URL_KEGIATAN + "view", {
                method: "POST",
                headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                    data_ke: this.page_first,
                    cari_value: this.cari_value,
                    page_limit : this.page_limit,
                })
            })
                .then(res => res.json())
                .then(res_data => {
                console.log(res_data)
                this.list_data = res_data.data;
                this.page_last = res_data.jml_data;
            });
        },

        btn_prev: function() {
            if (this.page_first > 1) {
                this.page_first--;
            } else {
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next: function() {
            if (this.page_first >= this.page_last) {
                this.page_first == this.page_last;
            } else {
                this.page_first++;
            }
            this.getView();
        },

        cari_data: function() {
            this.page_first = 1;
            this.getView();
        },

        routerToPlace(data){
            // console.log('telasooo')
            this.$router.push(data);
        },
    },

    mounted () {
        this.getView()
    },
}
</script>

<style>

</style>